import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Orders from './Orders';
import TopBar from './TopBar';
import appTheme from './theme';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';


export const UserContext = React.createContext(null);

const App = () => {
  const [accessCode, setAccessCode] = useState(localStorage.getItem("access_code"));
  
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <UserContext.Provider value={{ accessCode: accessCode, setAccessCode: setAccessCode }}>
        <TopBar accessCode={accessCode} />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Orders accessCode="accessCode" />} />
            <Route path="/orders" element={<Orders />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default function WrappedApp() {
  const { t } = useTranslation();
  return (
    <Suspense fallback={t("meta.please_wait")}>
      <App />
    </Suspense>
  )
}