import React, { useContext } from 'react'
import { AppBar, Box, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { ReactComponent as LogoSvg } from './logo.svg';
import { UserContext } from './App';
import { useTranslation} from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import i18n from 'i18next';


const TopBar = () => {    
    const { accessCode, setAccessCode } = useContext(UserContext);
    const { t } = useTranslation();
    const locales = {
      en: { title: 'EN' },
      fr: { title: 'FR' },
    };

    return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <LogoSvg />
          <Typography variant="h6" component="div" sx={{ fontSize: 18, fontWeight: 'bold', flexGrow: 1, ml: 2  }} noWrap={true}>
            e-planning
          </Typography>
          {accessCode && 
            <IconButton 
              aria-label={t("meta.logout")}
              title={t("meta.logout")}
              size="small"
              sx={{ mr: 1, fontSize: 12 }} color="inherit" onClick={() => {
              localStorage.setItem('access_code', "");
              setAccessCode("");
            }}>
              <LogoutIcon />
              {/* <Typography noWrap={true}>{t("meta.logout")}</Typography> */}
            </IconButton>}
            {Object.keys(locales).map((locale) => (
              <Box key={locale} component="div" style={{ fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal' }} sx={{ fontSize: i18n.resolvedLanguage === locale ? 14 : 12, cursor: 'pointer', display: 'inline', ml: 1 }} onClick={() => i18n.changeLanguage(locale)}>
                  {locales[locale].title}
              </Box>
            ))}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default TopBar;