import React, { useState } from 'react'
import { Typography, List, ListItem, ListItemText, Stack, Box } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OrderDetails from './OrderDetails';
import { useTranslation} from 'react-i18next';


const OrderItem = (props) => {
  const {order} = props;
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack direction="row"><Box sx={{ flexGrow: 1}}>{t("orders.down_payment")}</Box><FiberManualRecordIcon fontSize="small" color={order['30_percents'] ? "success" : "error"} /></Stack>
      <Stack direction="row"><Box sx={{ flexGrow: 1}}>{t("orders.balance")}</Box><FiberManualRecordIcon fontSize="small" color={order['70_percents'] ? "success" : "error"} /></Stack>
    </Stack>
  )
}

const OrderTitle = (props) => {
  const {order, orderHandler} = props;
  return (
    <Stack direction="row"  onClick={() => orderHandler(order)}  sx={{ cursor: 'pointer'}}>
      <Typography variant="h6" component="h3" sx={{ flexGrow: 1}}>{order.order_code}</Typography>
      <ArrowForwardIosIcon fontSize="small" color="primary" />
    </Stack>
  )
}

const OrderList = (props) => {
  const {orders} = props;
  const [currentOrder, setCurrentOrder] = useState(null);
  function orderHandler(order) {
    setCurrentOrder(order);
  }
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" component="h2" align="center" sx={{ mt: 2}} color="primary">
        {currentOrder ? `${t("meta.order")} ${currentOrder.order_code}` : t("meta.orders_list") }
      </Typography>
      {currentOrder ? 
        <OrderDetails order={currentOrder} orderHandler={orderHandler} />
        :
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {orders.map((order) => <ListItem key={order.order_code} divider={true}>
                                  <ListItemText primary={<OrderTitle order={order} orderHandler={orderHandler} />} secondary={<OrderItem order={order} />} />
                                </ListItem>)
          }
        </List>
      }
    </>
  )
}

export default OrderList