import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import REACT_APP_FIREBASE_KEY from "./.env";

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_KEY,
  authDomain: "e-planning-8ce72.firebaseapp.com",
  projectId: "e-planning-8ce72",
  storageBucket: "e-planning-8ce72.appspot.com",
  messagingSenderId: "914875826584",
  appId: "1:914875826584:web:58e3a04160babfb2776bed"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);