import { createTheme } from "@mui/material/styles";


 
const appTheme = createTheme({
  palette: {
    primary: {
        main: '#f9171f',
    },
    // secondary: pink,
  },
});

export default appTheme;