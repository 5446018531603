import { useEffect, useState, useContext } from "react";
import { collection, onSnapshot, where, query } from "firebase/firestore";
import { db } from "./firebase";
import OrderList from "./OrderList";
import { UserContext } from './App';
import { Stack, TextField, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation} from 'react-i18next';

const Orders = () => {
  const { accessCode, setAccessCode } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [waiting, setWaiting] = useState(true);
  const [wrongCode, setWrongCode] = useState(false);
  const { t } = useTranslation();

  function handleSubmit(event) {
    event.preventDefault();
    const value = event.target.accessCode.value;
    setWaiting(true);
    onSnapshot(query(collection(db, "clients"), where("access_code", "==", value)), (snapShot) => {
      if (snapShot.docs.length) {
        localStorage.setItem('access_code', value);
        setAccessCode(value);
        setWrongCode(false);
      } else {
        setWaiting(false);
        setWrongCode(true);
      }
    },
    (error) => { console.log(error); }
  );
  }

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, "orders"), where("client.access_code", "==", accessCode)), (snapShot) => {
      let list = [];
      snapShot.docs.forEach(doc => {
        list.push({id: doc.id, ...doc.data()})
      });
      setData(list);
      setWaiting(false);
    },
    (error) => { console.log(error); }
  );
  return () => unsub();
  }, [accessCode]);

  return (
    <>
      {accessCode && !wrongCode ? 
        data.length > 0 ? <OrderList orders={data}/> : <div>{waiting ? t("meta.please_wait") : t("meta.no_order")}</div>
        : <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
            {waiting ? t("meta.please_wait") : 
              <Stack
                component="form"
                sx={{ width: '40ch', mt:2 }}
                spacing={2}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  required
                  id="accessCode"
                  label={t("form.access_code")}
                  error={wrongCode}
                  helperText={`${wrongCode ? t("form.wrong_code") : ""}${t("form.type_code")}`}
                />
                <Button type="submit" variant="contained">{t("form.submit")}</Button>
              </Stack>
            }
          </Grid>
      }
    </>
  );
};

export default Orders;
